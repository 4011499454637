import styled, { css } from 'styled-components'
import { StylesConfig } from 'react-select'
import { FaLock, FaPencilAlt, FaTimes } from 'react-icons/fa'
import { defaultTheme } from '@app/styles/theme/default'
import { IoClose } from 'react-icons/io5'
import { MappedFieldType } from 'Nbee'

interface StyledCustomOptionContainerProps {
  isFocused?: boolean
}
export const customStyles: StylesConfig = {
  menuList: (style) => ({
    ...style,
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    // Important: the MultiCreatableCustom component has a fixed height
    maxHeight: '300px',
  }),
  placeholder: (style, { selectProps }) => {
    return {
      ...style,
      color: 'hsl(0, 0%, 50%)',
    }
  },
  option: () => ({
    // empty so style is reset
  }),
  multiValue: () => ({
    maxWidth: '200px', // fixed maxwidth in order to show up to 3 chips per row
    color: '#fff',
    fontSize: '0.8rem',
  }),
  multiValueLabel: () => ({
    // don't pass default styles
    fontFamily: defaultTheme.font.family,
  }),
  menu: (style, { selectProps }) => {
    const isOptionDisabled = (selectProps as any).isOptionDisabled()
    return {
      ...style,
      boxShadow: 'none',
      marginTop: 0,
      position: 'absolute',
      width: '100%',
      zIndex: 200,
      paddingTop: 4,
      paddingBottom: 4,
      borderRadius: '4px',
      top: '100%',
      color: isOptionDisabled ? 'grey' : 'black',
      background: '#fff',
      border: '1px solid #137FC0',
      fontFamily: defaultTheme.font.family,
    }
  },
  container: (style) => ({
    ...style,
    position: 'relative',
    width: '100%',
    fontSize: '0.9rem',
    pointerEvents: 'initial', // we need this so we can show tooltip on disabled elements
  }),
  control: (style, { isDisabled }) => ({
    ...style,
    // emulating InputField style
    minHeight: '43.11px',
    border: '1px solid rgba(34,36,38,0.15)',
    cursor: isDisabled ? 'default' : 'pointer',
    backgroundColor: isDisabled ? 'rgba(34, 36, 38, 0.03)' : 'white',
    color: isDisabled ? 'rgb(170, 170, 170)' : 'rgb(67, 73, 78)',
    fontFamily: defaultTheme.font.family,
  }),
}

const getChipStyle = ({ fieldType }: { fieldType?: MappedFieldType }) => {
  if (fieldType) {
    return css`
      background-color: ${({ theme }) =>
        theme.chips[fieldType].backgroundColor};
      transition: background-color 0.2s ease;
      &:hover {
        background-color: ${({ theme }) =>
          theme.chips[fieldType].backgroundColorHover};
    }
      .remove-icon {
        transition: color 0.2s ease;
        &:hover {
          color: ${({ theme }) => theme.chips[fieldType].closeIconColorHover}
      }
    `
  } else {
    return css``
  }
}

export const StyledCustomMultiValueContainer = styled.div<{
  fieldType: MappedFieldType
  onMouseEnter: () => void
  onMouseLeave: () => void
}>`
  min-height: 30px; // fixed min-height so that all chip have the same height
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.2rem;
  padding: 0.2rem 0.5rem;
  gap: 0.2rem;
  border-radius: 15px;
  ${getChipStyle}
`

export const StyledCustomMultiValueLabel = styled.div<{
  label: string
}>`
  display: flex;
  align-items: center;

  div {
    max-width: 149px; // 149px is the max width for the label in order to show 3 chips per row,
    // independently of the label length, without affecting how the remove icon is displayed
    margin-left: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${({ label }) => {
      return label === '<white-space>'
        ? css`
            font-style: italic;
          `
        : null
    }}
  }
`

export const StyledCustomMultiValueRemoveContainer = styled.div`
  display: flex;
`

export const SourceIcon = styled.img`
  width: 15px;
`

export const RemoveIcon = styled(IoClose)`
  cursor: pointer;
`

export const StyledCustomMultiValueHoverContainer = styled.div<{
  width: number
  height: number
}>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`
export const HoverEditIcon = styled(FaPencilAlt)`
  color: #fff;
  margin-left: 0.2rem;
  cursor: pointer;
  height: 10px;
  &:hover {
    color: rgba(215, 215, 215, 0.9);
  }
`

export const HoverRemoveIcon = styled(FaTimes)`
  &:hover {
    color: rgba(215, 215, 215, 0.9);
  }
`
export const HoverDivider = styled.div`
  width: 0.05rem;
  height: 80%;
  background-color: #fff;
`

export const StyledMenuTabContainer = styled.div`
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 3; ;
`

export const StyledMenuTab = styled.button<{
  isActive: boolean
}>`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ isActive }) => (isActive ? '#fff' : '#f8f9fa')};
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.8rem;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#fff' : '#f8f9fa')};
  color: ${({ isActive }) => (isActive ? 'black' : '#A9A9A9')};
  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
  :hover {
    background-color: #fff;
    border: 1px solid #fff;
  }
`

export const LockIcon = styled(FaLock)`
  margin-left: 0.5rem;
  color: #ff9800;
  &:hover {
    color: #f57c00;
  }
`

export const StyledCustomOptionContainer = styled.div<StyledCustomOptionContainerProps>`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-width: 1px;

  &:not(:first-of-type):before {
    content: '';
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: 1px;
    border-top: 1px solid #979797;
  }

  ${({ isFocused }) =>
    isFocused
      ? css`
          background-color: #efefef;
        `
      : null}
`

export const StyledCustomOptionFormulaContainer = styled.div`
  font-size: 0.9rem;
  padding: 0.2rem 0.4rem;
`

export const StyledCustomOptionFormulaLabel = styled.div`
  font-weight: 600;
  text-transform: capitalize;
`
export const StyledCustomOptionFormulaDescription = styled.div``
export const StyledCustomOptionSourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.9rem;
  padding: 0.2rem 0.4rem;
  width: 100%;
`

export const StyledCustomOptionSourceLabel = styled.div``

export const StyledCustomOptionPicklistLabel = styled.div`
  font-size: 0.8rem;
  color: grey;
`
