import styled from 'styled-components'

export const StepCompletedWrapper = styled.div`
  text-align: center;
  padding: 0;
  max-width: 490px;
  margin: 0 auto;
`

export const StepImage = styled.img`
  max-width: 350px;
  margin: 0.3rem auto 0.5rem;
`

export const BridgeSettingsWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  // max-width: 640px;
  margin: 0 auto;
`
